:local {
	.navigation {
		pointer-events: all;
		width: 57px;
		height: 142px;
		position: absolute;
		top: 3.75em;
		right: -2.5px;
		z-index: 99999;
		transform: translateY(-50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;

		a {
			cursor: pointer;
			text-decoration: none;
			transition: all .15s ease-in !important;
			display: flex;
			justify-content: center;
			align-items: center;
			zoom: .95;
			border-radius: 4px 0px 0px 4px;
			height: 50px;
			width: 100px;

			border-left: 2px solid #EC1B2E !important;
			border-bottom: 2px solid #EC1B2E !important;
			border-top: 2px solid #EC1B2E !important;
			background-color: #FFFFFF;

			position: relative;

			svg {
				left: 20px;
				position: absolute;
				g {
					stroke: #EC1B2E !important;
				}
			}

			&:hover {
				padding-left: 5px;
				margin-right: 5px;
				background-color: #EC1B2E;
				border-left: 2px solid transparent !important;
				border-bottom: 2px solid transparent !important;
				border-top: 2px solid transparent !important;
				svg g {
					stroke: #FFFFFF !important;
				}
			}
		}

		a:first-child {
			margin-bottom: 15px;
		}
	}

	.logo {
		width: 32px;
		margin: 12px auto !important;
	}
}
