:local {
	.campaignPoster {
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 100%;
		> div {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
		}
	}

	.campaignContainer {
		min-width: 457px;
		max-width: 457px;
		min-height: 692px;
		height: 692px;
		width: 457px;
		max-height: 692px;
		border-radius: 8px;
		overflow: hidden;
		margin-top: 85%;
		transition: all .15s ease-in-out;
		cursor: pointer;

		&:hover,
		&:active,
		&:focus {
			transform: scale(1.05);
		}
	}
}
