:local {
  .button {
    background-color: #ec1b30;
    // border: 6px solid #F9848F !important;
    border-radius: 999px;
    color: #ffffff !important; //because reveal.js
    cursor: pointer;
    font-size: 28px !important;
    font-weight: bold !important;
    letter-spacing: 2px;
    display: inline-block;
    margin: 0 auto;
    padding: 14px 48px !important;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transform: scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .25s ease-in !important;
    text-transform: capitalize;
    letter-spacing: -0.005245px;

    box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.18);

    width: 325px;
    height: 58px;

    &:hover {
      transform: scale(1.05);
    }
  }

  .small {
    height: 38px !important;
    font-size: 22px !important;
    width: 290px;
    font-weight: normal !important;
  }
}
