
#root {
	display: flex;
    width: 100%;
    height: 100%;
}

.swiper-container {
    height: 100%;
    width: 100%;
}
