:local {
	.btn {
		height: 60px;
		width: 60px;
		border-radius: 50%;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 10px 5px rgba(140, 140, 140, 0.5);
		cursor: pointer;
		transition: all .25s ease-in-out;
		z-index: 99999;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.btn:hover,
	.btn:active,
	.btn:focus {
		background-color: #EC1B2E;
		width: 70px;
		height: 70px;
		svg g {
			stroke: #FFFFFF;
		}
	}

	.btnNext {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(-1em, -50%);
	}

	.btnLeft {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(1em, -50%);
	}
}
