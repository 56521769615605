:local {
	.countrySelection {
		width: 80px;
		position: fixed;
		left: 0;
		top: 2em;
		z-index: 9999999999999;
	}

	.btn {
		border: 0px 4px 4px 0px;
		height: 50px;
		transition: all .15s ease-in;
		border-right: 2px solid #EC1B2E;
		border-bottom: 2px solid #EC1B2E;
		border-top: 2px solid #EC1B2E;
		background-color: #FFFFFF;
		margin-bottom: 15px;
		zoom: .95;

		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;

		span {
			background-repeat: no-repeat;
			height: 100%;
			width: 100%;
			max-height: 21px;
			max-width: 51px;
			background-position: center;
			background-size: contain;
			display: block;
		}
	}

	.btn:hover {
		padding-left: 5px;
		margin-right: 5px;
	}

	.btn.btnActive {
		background-color: #EC1B2E;
		border-right: 2px solid transparent;
		border-bottom: 2px solid transparent;
		border-top: 2px solid transparent;
	}
}
