:local {
	.testimonial {
		width: 533px;
		border: 1px solid #D3D3D3;
		border-radius: 16px;
		padding: 35px 40px;

		display: flex;
		flex-direction: column;
		justify-content: center;

		> p {
			color: #434343;
			margin-top: auto;
			margin-bottom: auto;
			font-size: 20px;
		}
	}

	.description {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: auto;

		.logo {
			min-width: 45px;
			min-height: 31px;
			height: auto;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			margin-right: 16px;
		}

		.title {
			font-weight: bolder;
			color: #000000;
			font-size: 23px;
			margin-top: auto;
		}
	}

	.stars {

		display: block;
		width: auto;
		height: 30px;
		position: relative;
		margin-top: auto;

		.image {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			z-index: 8;
		}
	}

	.imagePercent {
		position: absolute;
		background-color: rgba(255, 255, 255, 0.85);
		bottom: 0;
		right: 0;
		z-index: 10;
		top: 0;
	}
}
