:local {
	.swiperGrid {
		padding-top: 12%;
		padding-bottom: 12%;
	}

	.swiperRow {
		flex-basis: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		justify-content: flex-end;
		align-items: center;
		margin-left: 2.5em;
		margin-right: 2.5em;
		> div {
			margin: 18px;
			min-height: 200px;
		}
	}

	.firstRow > div {
		min-height: 315px;
	}
}
