.reveal > .overlay.overlay-preview.loaded .viewport iframe {
    background: white;
}


.loading-overlay {
    transform: scale(1);
    background-color: #191919;
    position: fixed;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #151515;
}
